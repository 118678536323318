@import url("https://fonts.googleapis.com/css?family=Raleway:400");

* {
	box-sizing: border-box;
}

@property --angle {
  syntax: '<angle>';
  initial-value: 90deg;
  inherits: true;
}

@property --gradX {
  syntax: '<percentage>';
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: '<percentage>';
  initial-value: 0%;
  inherits: true;
}

.box {
	--d: 2500ms;
	--angle: 90deg;
	--gradX: 100%;
	--gradY: 50%;
	--c1: rgba(168, 239, 255, 1);
	--c2: rgba(168, 239, 255, 0.1);
  
	position: relative;
	border-radius: 6px;
	background: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn);
	animation: borderRotate var(--d) linear infinite forwards;
  }
  
  .box::before {
	padding: 0.2rem; /* Adjust to control the border thickness */
	z-index: -1;
  }
  
  @keyframes borderRotate {
	100% {
	  --angle: 420deg;
	}
  }
  